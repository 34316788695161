<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-lg-6"
        label="Nome"
        v-model="category.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />

      <FormInputTextWidget
        class="col-lg-6"
        label="Ordinamento"
        :min="0"
        :step="1"
        type="number"
        v-model.number="category.rank"
        :errorMsg="errorHash.rank"
        @input="removeFieldError('rank')"
      />
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <button class="btn btn-primary px-5">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';

import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';

export default {
  mixins: [validateMixin],
  components: {
    FormContainer,
    FormInputTextWidget,
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  props: {
    category: {
      type: Object,
      default: () => ({
        name: null,
        rank: 0,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      if (!this.category.name) {
        this.addError('Inserisci il nome', 'name');
      }

      return !this.hasErrors;
    },
    submit () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.isValid()) {
        return;
      }

      const categoryToReturn = { ...this.category };

      this.$emit('submitForm', categoryToReturn);
    },
  },
};

</script>
